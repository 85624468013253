.wrap {
  display: flex; /* שימוש ב-Flexbox */
  align-items: center; /* מרכז אנכי */
  justify-content: center; /* מרכז אופקי */
  min-height: 90vh; /* גובה המסך כולו */
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0% 90%);
  position: relative;

}

.info {
  text-align: center;
}

/* התאמה למסכים בינוניים */
@media (max-width: 768px) {
  .water-effect-container {
    background-size: contain; /* מבטיח שהתמונה תתאים למסך */
    background-position: top center; /* ממקד את החלק העליון */
  }
}

/* התאמה למסכים קטנים מאוד */
@media (max-width: 480px) {
  .wrap {
    display: flex; /* שימוש ב-Flexbox */
    align-items: center; /* מרכז אנכי */
    justify-content: center; /* מרכז אופקי */
    min-height: 70vh; /* גובה המסך כולו */
  }
}
