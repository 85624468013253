.faqs__header {
    text-align: center;
    margin-bottom: 2rem;
}

.faqs__header h2 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--primary);
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: floating 3s infinite ease-in-out;
}

.faqs__header p {
    font-size: 1.2rem;
    color: var(--bg-primary-rgb);
    max-width: 600px;
    margin: 0 auto;
}

@keyframes floating {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0px); }
}

.faqs__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 4rem;
    align-items: start;
}

.card.faq:hover {
    cursor: pointer;
    background: var(--bg-primary);
    transition: transform 0.3s ease, background 0.3s ease;
    transform: scale(1.02);
}

.faq > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

.faq h5 {
    line-height: 1.4;
    color: #008CFF; /* תכלת */
    font-weight: bold;
    transition: color 0.3s ease-in-out; /* אפקט מעבר */
}

.faq:hover h5 {
    color: #006BB3; /* גוון כהה יותר בעת ריחוף */
}


.faq__icon {
    font-size: 1.2rem;
    color: var(--bg-primary-rgb);
    background: transparent;
}

.faq__answer {
    margin-top: 1rem;
    opacity: 0;
    animation: animateAnswer 500ms ease-in forwards;
}

@keyframes animateAnswer {
    to {
        opacity: 1;
    }
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .faqs__container {
        grid-template-columns: 1fr;
    }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .faqs__container {
        gap: 1rem;
    }

    .faqs__header h2 {
        font-size: 2.5rem;
    }
}
