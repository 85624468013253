#skill .skill__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

@media (max-width: 1024px) {
  #skill .skill__container {
    grid-template-columns: 1fr;
  }
  #skill .skill__container .skill__card {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  #skill .skill__content {
    padding: 1rem;
  }
}

@media (max-width: 600px) {
  #skill .skill__container {
    gap: 1rem;
  }
  #skill .skill__container .skill__card {
    width: 100%;
    padding: 2rem 1rem;
  }
}
