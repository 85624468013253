.hero-section{
  margin-top: 60vh;
}



.paragraph {
  display: flex;
  font-size: 60px;
  line-height: 1;
  padding: 40px;
  max-width: 1280px;
  color: var(--text-color);
  flex-wrap: wrap;
}

.word {
  position: relative;
  margin-right: 12px;
  margin-top: 12px;
}

.shadow {
  position: absolute;
  opacity: 20%;
}


@media (max-width: 768px) {
  .hero-section {
    height: 70px; /* התאמה אוטומטית לגובה התוכן */
    padding-bottom: 10px; /* הקטנת הרווחים */
   margin-bottom: 20px;
  }


  .paragraph {
    font-size: 40px; /* גודל גופן קטן יותר למסכים קטנים */
    padding: 20px; /* מרווחים קטנים יותר */
    
  }

  .word {
    margin-right: 8px;
    margin-top: 8px;
  }
}

@media (max-width: 480px) {
  .paragraph {
    font-size: 30px; /* גודל גופן מותאם למסכים קטנים מאוד */
    padding: 10px; /* מרווחים צרים יותר */
  }

  .word {
    margin-right: 5px;
    margin-top: 5px;
  }
}
