.text-effect {
  font-size: 5rem;
  font-weight: bold;
  color: var(--text-color); /* שימוש במשתנה דינמי */
}

.highlight {
  color: var(--highlight-color); /* שימוש במשתנה דינמי */
}

@media (max-width: 768px) {
  .text-effect {
    font-size: 2rem;
  }
}
