.water-effect-container {
  position: relative;
  width: 100%;
  height: 100vh; /* גובה מלא למסכים גדולים */
  overflow: hidden;
  z-index: 10;
  background-size: cover; /* מכסה את כל השטח */
  background-position: center; /* מיקום במרכז */
  background-attachment: fixed; /* תמונה קבועה */
}


.water-effect-content {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 23;
}

.slider-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  z-index: 1;
}

.water-effect-title {

  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}


/* התאמה למסכים קטנים */
@media (max-width: 768px) {
  .water-effect-container {
    height:10%; /* גובה 60% למסכים קטנים */
    background-size: contain; /* מתאים את התמונה לגודל האלמנט */
    background-position: top; /* ממקם את התמונה בחלק העליון */
    background-attachment: scroll; /* תמונה נגללת במסכים קטנים */
  }
}

/* התאמה למסכים קטנים מאוד */
@media (max-width: 480px) {
  .water-effect-container {
    height: 10%; /* גובה 50% למסכים קטנים מאוד */
    background-size: contain; /* מתאים את התמונה */
    background-position: center; /* ממקם את התמונה במרכז */
    background-attachment: scroll; /* תמונה נגללת במסכים קטנים מאוד */
  }
}