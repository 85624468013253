.portfolio__categories {
    background-color: var(--color-light); /* צבע רקע ברירת מחדל */
    transition: background-color 0.3s ease; /* מעבר צבע חלק */
    border-radius: var(--border-radius-2); /* פינות עגולות */
    padding: 1rem; /* ריווח פנימי */
    
}

.portfolio__project {
    background-color: var(--color-light); /* צבע רקע ברירת מחדל */
    border-radius: var(--border-radius-2); /* פינות עגולות */
    padding: 1rem; /* ריווח פנימי */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* צל בסיסי */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* אפקט מעבר חלק */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .portfolio__project:hover {
    transform: translateY(-10px); /* תזוזה קלה למעלה */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); /* צל מוגבר */
    background-color: var(--color-primary); /* שינוי צבע רקע */
    color: var(--color-light); /* שינוי צבע טקסט */
  }
  .portfolio__project.active {
    background-color: var(--color-secondary); /* צבע רקע כאשר הכרטיס פעיל */
    transform: scale(1.02); /* הגדלה קטנה */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3); /* צל חזק יותר */
  }

  .portfolio__project-cta {
    display: flex;
    gap: 1rem;
  }
  
  .portfolio__project-cta .btn {
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-2);
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .portfolio__project-cta .btn:hover {
    background-color: var(--color-light);
    color: var(--color-primary);
  }

.btn.cat__btn {
    text-transform: capitalize;
    transition: var(--transition);
}

.btn.cat__btn:hover {
    transform: translateY(0);
}



.card.portfolio__project {
    animation: animateProject 800ms ease-in forwards;
}



.project-title {
    text-align: center; /* מיישר את הכותרת למרכז */
    margin: 0 auto; /* מרכז את הכותרת */
    max-width: 600px; /* מגביל את הרוחב */
    font-size: 48px; /* גודל גופן לכותרת */
    font-weight: bold; /* מדגיש את הכותרת */
  }
  
  .text-project {
    text-align: center; /* מיישר את הטקסט למרכז */
    margin: 1rem auto; /* מוסיף רווח סביב הטקסט */
    max-width: 600px; /* מגביל את הרוחב */
    font-size: 16px; /* גודל גופן לטקסט */
    color: var(--text-color); /* שימוש בצבע הגלובלי */
  }
  
  #portfolio {
    display: flex;
    flex-direction: column;
    align-items: center; /* מרכז את התוכן אופקית */
    justify-content: center; /* מרכז את התוכן אנכית */
    gap: 1rem; /* רווח בין הכותרת לטקסט */
    padding: 2rem; /* ריווח פנימי */
    margin-top: 10rem; /* מרווח עליון */
    margin-bottom: 2rem; /* מרווח תחתון */
  }
  

  .portfolio__project-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    border-radius: var(--border-radius-2); /* פינות עגולות לתמונה */
    margin-bottom: 1rem;
  }
  
  .portfolio__project-image img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease; /* אפקט מעבר חלק */
  }
  
  .portfolio__project:hover .portfolio__project-image img {
    transform: scale(1.1); /* הגדלה קלה של התמונה */
  }



.portfolio__project h3 {
    font-size: 1rem;
    margin: 0.5rem 0;
    font-weight: bold;
  }
  
  .portfolio__project p {
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
    color: var(--text-color);
  }


  /* צבעים שונים לכל מילה */
.highlight {
    font-weight: bold;
    animation: colorChange 2s infinite;
  }
  
  .highlight.frontend {
    color: #ffbe33; /* צבע כתום */
  }
  
  .highlight.backend {
    color: #24cc43; /* צבע ירוק */
  }
  
  .highlight.apps {
    color: #008cff; /* צבע כחול */
  }
  
  .highlight.games {
    color: #e727e7; /* צבע ורוד */
  }
  
  /* אפקט צבע נע */
  @keyframes colorChange {
    0% {
      filter: brightness(1);
    }
    50% {
      filter: brightness(1.5);
    }
    100% {
      filter: brightness(1);
    }
  }
  
@keyframes animateProject {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.portfolio__project-image {
    width: 100%;
    height: fit-content;
    min-height: 7rem;
    max-height: 15rem;
    background: var(--color-light);
    border-radius: var(--border-radius-2);
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.portfolio__project p {
    margin: 0.5rem 0 2rem;
}

.portfolio__project-cta {
    display: flex;
    gap: 1rem;
}

.portfolio__project:hover .btn.sm {
    border-color: var(--color-white);
}

.portfolio__project:hover .btn.sm.primary {
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .portfolio__projects {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

/* עיצוב כרטיסים למסכים קטנים עם גלילה אופקית */
@media screen and (max-width: 600px) {
    .portfolio__projects {
        display: flex; /* משנה לגריד לשורה גמישה */
        flex-wrap: nowrap; /* מונע שבירת כרטיסים לשורות נוספות */
        overflow-x: auto; /* מאפשר גלילה אופקית */
        gap: 1rem; /* רווח בין הכרטיסים */
        padding: 1rem; /* מוסיף רווח פנימי */
    }

    .portfolio__projects::-webkit-scrollbar {
        height: 8px; /* גובה הגלילה בדפדפן */
    }

    .portfolio__projects::-webkit-scrollbar-thumb {
        background-color: var(--color-primary); /* צבע פס הגלילה */
        border-radius: 4px; /* עיגול פס הגלילה */
    }

    .portfolio__projects::-webkit-scrollbar-track {
        background: var(--color-light); /* רקע פס הגלילה */
    }

    .portfolio__project {
        flex: 0 0 80%; /* רוחב הכרטיס (80% מרוחב המסך) */
        max-width: 300px; /* רוחב מקסימלי */
    }
}