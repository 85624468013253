.services {
  height: 100%;
  overflow: hidden;
  display: flex;
}

.sSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sTitle {
  font-size: 88px;
  margin-bottom: 24px;
}

.serviceList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.service {
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background-color: #2f213f;
  display: flex;
  align-items:start;
  gap: 16px;
  cursor: pointer;
}


.sSection.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* מיישר את כל התוכן להתחלה */
  text-align: right; /* יישור טקסט לימין */
  direction: rtl; /* מגדיר כיוון מימין לשמאל */
}

.sSection.right p {
  margin-right: 0; /* מבטל מרווח משמאל */
  padding-right: 16px; /* מוסיף ריווח מימין */
  text-align: right; /* יישור טקסט לימין */
  line-height: 1.8; /* משפר את הקריאות */
}


.serviceIcon {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.serviceIcon img {
  width: 100%; /* מתאים לגודל של הורה */
  height: 100%; /* מתאים לגודל של הורה */
  border-radius: 50%; /* מוודא שהתמונה תישאר עגולה */
  object-fit: cover; /* חיתוך והכנסת התמונה בדיוק לעיגול */
}

.serviceInfo h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}

.serviceInfo h3 {
  font-size: 13px;
  font-weight: 300;
}

.service:nth-child(1) .serviceIcon {
  background-color: #dd4c62;
}
.service:nth-child(2) .serviceIcon {
  background-color: #025656;
}
.service:nth-child(3) .serviceIcon {
  background-color: #6c5c0c;
}

.counterList {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.counter {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.counter h1 {
  font-size: 48px;
  color: #dd4c62;
}

.counter p {
  font-size: 13px;
  width: 120px;
}


@media (max-width: 1536px) {
  .service,
  .counterList {
    width: 70%;
  }
}

@media (max-width: 1280px) {
  .sTitle {
    font-size: 128px;
  }

  .service {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .sTitle {
    font-size: 48px;
  }

  .service,
  .counterList {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .services {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px; /* מוסיף מרווח פנימי של 16 פיקסלים מכל הצדדים */
  }

  .sSection {
    width: 100%;
    margin-bottom: 16px; /* רווח בין הסקשנים */
  }

  .sSection.right {
    display: flex; /* ודא שה-right יופיע */
    flex-direction: column;
    align-items: center;
  }
}

.highlightmarketing {
  color: #FF5733; /* צבע כתום */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-ptext {
  font-weight: bold; /* משקל הגופן */
  text-transform: uppercase; /* אותיות גדולות */
  background: linear-gradient(90deg, #7ea7ff, #05a3ff, #fdfaff, #00e1ff);
  background-size: 200% 200%; /* מגדיל את שטח הרקע ליצירת אפקט המעבר */
  -webkit-background-clip: text; /* מצביע שהטקסט יחתוך את הרקע */
  -webkit-text-fill-color: transparent; /* מסיר את צבע המילוי של הטקסט */
  animation: gradient-animation 3s ease infinite; /* הוספת האנימציה */
}
