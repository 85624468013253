

@keyframes contact-bg {
  0% {
    background-color: #3d3d3d;
  }
  25% {
    background-color: #ced8e4;
  }
  50% {
    background-color: #1e81f3;
  }
  75% {
    background-color: #4265ff;
  }
  100% {
    background-color: #3d3d3d;
  }
}
