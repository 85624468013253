.image-container {
    width: 100%; /* תופס את רוחב המסך */
    height: 100vh; /* גובה מלא של המסך */
    display: flex;
    justify-content: center;
    align-items: center; /* ממרכז את התמונה אנכית */
    overflow: hidden; /* מונע גלישה */
  }
  
  .responsive-image {
    width: 80%; /* ברירת מחדל: תופסת 80% מרוחב */
    height: auto; /* שומרת על יחס התמונה */
    max-height: 90vh; /* מגבלת גובה */
    object-fit: contain; /* מתאים לגודל תוך שמירה על יחס */
  }
  
  @media (max-width: 1024px) {
    .responsive-image {
      width: 80%; /* במסכים קטנים יותר */
    }
  }
  
  @media (max-width: 768px) {
    .image-container {
      justify-content: center; /* מרכז אופקי */
      align-items: center; /* מרכז אנכי */
    }
  
    .responsive-image {
      width: 95%; /* תופסת 80% מרוחב המסך */
      max-height: 70vh; /* התאמה לגובה מסך קטן */
    }
  }
  