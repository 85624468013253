.navbar {
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 100%;
  height: 60px; /* הגובה המינימלי של התפריט */
  position: absolute;
  left: 0;
  z-index: 1000;
  height: auto;

}
.navbar.drop {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(var(--bg-secondary-rgb), 0.9);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  box-shadow: var(--box-shadow);
  animation: drop 1s;
}
@keyframes drop {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
.navbar .navbar__links {
  gap: 1rem;
  align-items: center;
}
.navbar .nav__item {
  cursor: pointer;
  font-size: var(--font-size-base);
  font-weight: 500;
  transition: var(--transition);
}
.navbar .nav__item:hover {
  color: var(--primary);
}
.navbar .cancel__btn,
.navbar .menu__btn {
  font-size: 30px;
  background: transparent;
  display: none;
}



.navbar {
  padding: 0 20px; /* רווח פנימי */
}

.navbar .logo__container {
  margin-left: auto; /* ממקם את הלוגו בצד ימין */
  max-width: 100%; /* מתאימה את הרוחב למסך קטן */
}

.navbar .logo__container img {
  width: auto; /* מאפשר להתאים את הרוחב */
  max-height: 50px; /* מתאימה את גובה התמונה */
}


/* Responsiveness */
@media (max-width: 1000px) {
  .navbar .social__handles {
    display: none;
  }
}
@media (max-width: 950px) {
  .navbar .navbar__links {
    flex-direction: column;
    align-items: stretch;
    position: fixed;
    width: 320px;
    max-width: 100%;
    min-height: 100vh;
    height: 100%;
    top: 0;
    left: -340px;
    z-index: 1100;
    background: var(--bg-secondary);
    transition: var(--transition);
    padding: 1rem;
  }
  .navbar .navbar__links.visible {
    left: 0;
  }
  .navbar .cancel__btn,
  .navbar .menu__btn {
    display: flex;
  }


  .navbar {
    padding: 0 20px; /* רווח פנימי */
  }

  .navbar .logo__container {
    margin-left: auto; /* ממקם את הלוגו בצד ימין */
    max-width: 100%; /* מתאימה את הרוחב למסך קטן */
  }

  .navbar .logo__container img {
    width: auto; /* מאפשר להתאים את הרוחב */
    max-height: 50px; /* מתאימה את גובה התמונה */
  }





}
