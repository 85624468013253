.logo__container {
    background: transparent;
    width: 60px; /* שינוי הרוחב */
    height: 60px; /* שינוי הגובה */
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo__container .icon__container {
    background: transparent;
        width: 50px;
    height: 50px;

}

.logo__container .icon__container img {
    width: 100%; /* מתאים את התמונה לגודל הקונטיינר */
    height: 100%;
    object-fit: contain; /* מבטיח שהתמונה לא תיחתך */
}

.logo__container .title {
    font-size: 1.5rem;
    font-weight: 600;
}
