#contact {
    display: flex;
    flex-direction: row; /* פריסה אופקית */
    height: 100%; /* גובה מלא של המסך */
    
  
  }
  


  
  #contact .container {
    
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    color: #fff; /* טקסט לבן */
    padding: 30px;
  }
  
  #contact .contact__info {
    margin: 30px 0;
    gap: 10px;
  }
  
  #contact .icon__container {
    background: var(--primary);
    color: var(--color-white);
  }
  
  #contact .control {
    display: block;
    border: var(--gray-border);
    border-width: 2px;
    width: 100%;
    padding: 0 15px;
    height: 50px;
    border-radius: var(--radius-1);
    margin: 15px 0px;
    font-size: 15px;
    background: var(--bg-secondary);
    resize: none;
    transition: var(--transition);
    color: var(--text-color);
  }
  
  #contact .control:focus {

  }
  
  #contact textarea.control {
    height: 100px;
    padding: 10px;
  }
  
  #contact .form__bottom {
    margin-top: 30px;
    text-align: center;
  }
  
  #contact .form__bottom .btn {
    padding: 10px 20px;
  }
  
  
  .sub__heading {
    font-size: 24px; /* גודל גופן */
    font-weight: bold; /* גופן מודגש */
    text-align: right; /* יישור לימין */
    margin-bottom: 10px; /* מרווח בין הכותרת לקו */
    position: relative; 
    margin-top: 40px;/* הכרחי להוספת ה-::after */
    color:  var(--text-color);
  
  }
  
  .sub__heading::after {
    content: ''; /* אלמנט ריק עבור הקו */
    display: block; /* גורם לקו להופיע */
    width: 8%; /* קו באורך 50% מהרוחב הכולל */
    height: 2px; /* גובה הקו */
    background-color:var(--bg-secondary); /* צבע הקו */
    margin-top: 5px; /* רווח בין הכותרת לקו */
    margin-left: auto; /* יישור הקו לימין */
    margin-right: 0; /* הקו נשאר מיושר לימין */
  }
  

  .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.social-icon {
  font-size: 40px; /* גודל האייקון */
  color: var(--primary);
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  color: var(--text-color);
}

  /* Responsiveness for smaller screens */
  @media (max-width: 930px) {
    #contact {
      flex-direction: column; /* מעבר לפריסה אנכית */
    }
  
    #contact .contact-image {
      height: 50vh; /* גובה מותאם למסכים קטנים */
      min-height: 300px; /* גובה מינימלי */
      order: -1;
    }
  
    #contact .container {
      height: auto; /* גובה מותאם לתוכן */
    }
  }
  
  
  