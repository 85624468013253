.hero {
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  background-color: transparent; /* מבטל את הרקע הכללי */
  z-index: 1; /* מוודא שהרכיב מעל רכיבים אחרים */
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}



.hTitle .small-text {
  font-size: 3.5rem; /* גודל גופן */
  text-align: center; /* מיושר למרכז */
  margin: 50px auto; /* מרכז את האלמנט */
  animation: fadeInUp 3s ease-in-out infinite; /* אנימציה */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* הצללה */
  background: linear-gradient(90deg, #ff7eb3, #7ea7ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* אנימציה */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}


.hTitle {
  text-align: center; /* ממרכז את הטקסט */
  font-size: 36px; /* מקטין את הכותרת */
}


.custom-text {
  font-size: 1rem; /* גודל טקסט יותר גדול */
  font-weight: bold; /* מדגיש את הטקסט */
  line-height: 1.2; /* מרווח בין שורות */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-text {
  font-size: 4.5rem; /* גודל הגופן */
  font-weight: bold; /* משקל הגופן */
  text-transform: uppercase; /* אותיות גדולות */
  background: linear-gradient(90deg, #7ea7ff, #ff758c, #247397, #ff7eb3);
  background-size: 300%; /* גודל הרקע */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 4s ease infinite;
  text-align: center; /* מיושר למרכז */
  margin: 20px 0;
}

.hImg {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 80%;
  width: max-content;
  
}


.hSection {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hTitle {
  margin-top: 50px;
  font-size: 100px;
  color: pink;
}

.hTitle span {
  color: var(--text-color);
  text-align: center; /* ממרכז את הטקסט */
  font-size: 2.5rem; /* גודל גופן גדול */
  line-height: 1.3; /* מרווח קטן יותר בין השורות */
  color: white; /* צבע טקסט */
  margin: 0 auto; /* מרכז את האלמנט */
}

.awards {
  width: 30%;
}

.awards p {
  font-size: 14px;
  color: #ddd;
  margin: 16px 0px;
}

.awardList {
  display: flex;
  gap: 8px;
}

.awardList img {
  width: 36px;
  height: 36px;
  padding: 8px;
  background-color: white;
  border-radius: 100%;
}

.scroll {
  margin-bottom: 50px;
  width: max-content;
}

.hSection.right {
  align-items: end;
}

.follow {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background-color: #2f204e !important;
  border-bottom-right-radius: 10px;
  margin-right: 20px;
}

.follow img {
  width: 20px;
  height: 20px;
}

.followTextContainer {
  width: 20px;
  height: 20px;
}

.followText {
  background-color: #dd4c62 !important;
  font-size: 12px;
  rotate: 90deg;
  width: max-content;
  height: 100%;
  transform-origin: left top;
  transform: translateY(-20px);
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 10px;
}

.bubbleContainer {
  display: flex;
  justify-content: center; /* יישור למרכז */
  padding: 10px;
}

.bubble {
  height: auto;
  background-color: white !important;
  color: #555;
  padding: 24px;
  font-size: 18px;
  border-radius: 20px 20px 0px 20px;
  width: 300px; /* רוחב קבוע */
  height: auto; /* גובה קבוע */
}

.bubbleContainer img {
  width: 50px;
  height: 50px; /* התאמה לגודל שווה */
  border-radius: 50%; /* עיגול מלא */
  object-fit: cover;
  background-color: #dd4c62; /* ורוד לרקע */
  border: 2px solid white; /* מסגרת לבנה אם נדרשת */
}

.certificate {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  line-height: 10px;
  font-weight: 300;
  color: #ddd !important;
}

.certificate img {
  width: 100%;
  height: 100%;
}

.contactLink {
  margin-bottom: 50px;
}

.contactButton {
  position: relative;
}

.circleText {
  letter-spacing: 3px;
  font-size: 20px;
}

.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1536px) {
  .hTitle {
    font-size: 72px;
  }
}

@media (max-width: 1280px) {
  .bg {
    height: 60%;
    top: auto;
    bottom: 0;
  }

  .hImg {
    height: 100%;
  }

  .hTitle {
    font-size: 88px;
  }

  .awards {
    width: 60%;
  }

  .bubbleContainer {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .bg {
    height: 50%;
  }

  .hTitle {
    font-size: 72px;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .gradient-text {
    font-size: 2.5rem; /* גודל הגופן */
    margin: 20px 0;
  }

  .bg {
    height: 60%;
  }

  .hSection {
    width: 100%;
  }

  .hSection.left {
    align-items: center;
    text-align: center;
    gap: 36px;
    margin-bottom: 40px; /* מוסיף מרווח מתחת לטקסט */
  }



  
  .hTitle .small-text {
    font-size: 1.5rem; /* גודל גופן */
    margin: 10px auto; /* מרווח במרכז */
  }

  .hTitle {
    font-size: 36px; /* מקטין את הכותרת */
  }


  .hImg {
    width: 100%; /* מבטיח שהתמונה תתפוס את רוחב המסך */
    height: auto; /* מאפשר לתמונה להתאים את הגובה */
    margin-top: 20px; /* מרווח מעל התמונה */
  }

  .awards {
    width: 100%;
  }

  .awardList {
    justify-content: end;
  }

  .hSection.right {
    justify-content: end;
  }

  .awardList {
    justify-content: center; /* מרכז את העיגולים */
    margin: 10px auto; /* מוסיף מרווח למעלה ולמטה */
  }

  .follow,
  .bubbleContainer,
  .certificate {
    display: none;
  }

  .contactButton svg {
    width: 100px; /* מקטין את הרוחב */
    height: 100px; /* מקטין את הגובה */
  }

  .contactButton circle {
    r: 70; /* מקטין את הרדיוס של העיגול */
  }

  .arrow svg {
    width: 30px; /* מקטין את החץ */
    height: 30px;
  }
}
