.skill__card {
  padding: 30px 40px;
  border: 1px solid transparent;
  transition: var(--transition);
  border-radius: var(--border-radius-3);
  background: rgba(var(--card-rgb), 0.1);
}

.skill__card:hover {
  background: transparent;
  border-color: var(--color-primary);
  cursor: default;
}

.skill__card h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.skill__card .skill__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.skill__card .skill__details {
  display: flex;
  gap: 1rem;
}
.skill__card .skill__icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.skill__card .skill__details .skill__name {
  font-size: 15px;
  font-weight: 500;
}

.skill__card .skill__details .skill__level {
  font-size: 14px;
}

@media (max-width: 350px) {
  .skill__card .skill__details .skill__name {
    font-size: 12px;
  }
  .skill__card .skill__details .skill__level {
    font-size: 10px;
  }
}
